export function getFromSessionStorage(key: string) {
  try {
    return sessionStorage.getItem(key);
    // browsers such as safari throw an error in private mode
  } catch (ex) {
    return null;
  }
}
export function writeToSessionStorage(key: string, value: string) {
  try {
    return sessionStorage.setItem(key, value);
    // browsers such as safari throw an error in private mode
  } catch (ex) {
    return null;
  }
}
export function removeFromSessionStorage(key: string) {
  try {
    return sessionStorage.removeItem(key);
    // browsers such as safari throw an error in private mode
  } catch (ex) {
    return null;
  }
}
