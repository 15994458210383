import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { getFromSessionStorage, removeFromSessionStorage, writeToSessionStorage } from '@hubcms/utils-browser';
import { log, warn } from '@hubcms/utils-monitoring';
import { AuthContext } from '@hubcms/data-access-auth';
import { ApiIdentity } from '@hubcms/domain-auth';

const SESSION_STORAGE_KEY = 'oauth_idtoken';

const makeProfileCall = (shouldlogout: boolean) =>
  fetch(`/api/auth/profile/${shouldlogout ? '?logout=true' : ''}`, {
    credentials: 'include',
  })
    .then(r => r.json())
    .then((data: ApiIdentity) => {
      if (data?.user) {
        const oneHour = 3600 * 1000;
        writeToSessionStorage(SESSION_STORAGE_KEY, JSON.stringify({ ...data, expires: Date.now() + oneHour }));
      } else {
        removeFromSessionStorage(SESSION_STORAGE_KEY);
      }
      return data;
    });

export const OAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<null | ApiIdentity['user']>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchUserCredentialsFromCookie = () =>
      makeProfileCall(false)
        .then((data: ApiIdentity) => {
          setIsLoading(false);
          if (data?.user) {
            setUser(data.user);
            setIsAuthenticated(data.user !== null);
          }
          return data;
        })
        .catch(e => warn('error loading profile', e));
    try {
      const userDataString = document.getElementById('user-data')?.textContent;
      const userData = JSON.parse(userDataString ?? '');
      if (userData.user) {
        userData.user.givenName = userData.user.given_name;
        userData.user.familyName = userData.user.family_name;
        setUser(userData.user);
        setIsAuthenticated(Object.keys(userData.user).length > 0);
      }
      setIsLoading(false);
      return () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
    } catch (e) {
      log(e);
    }

    // the code below can be removed in CW-506
    const decodedToken = getFromSessionStorage(SESSION_STORAGE_KEY);
    if (!decodedToken) {
      fetchUserCredentialsFromCookie();
    } else {
      const parsedToken = JSON.parse(decodedToken);
      if (!parsedToken.expires || parsedToken.expires < Date.now()) {
        fetchUserCredentialsFromCookie();
      } else {
        setUser(parsedToken.user);
        setIsLoading(false);
        setIsAuthenticated(parsedToken.user !== null);
      }
    }
    return () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
  }, []);

  const login = (returnUrl?: string) => {
    if (!isAuthenticated) {
      window.location.href = '/auth/login/';
    }
    window.location.href = returnUrl || '/';
  };

  const logout = (logoutServiceUrl?: string) => {
    makeProfileCall(true)
      .finally(() => {
        window.location.href = logoutServiceUrl || '/';
      })
      .catch(e => {
        throw e;
      });
    return false; // no redirects; presume that the service is called directly
  };

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated, isLoading }}>{children}</AuthContext.Provider>;
};
